import React, { useContext } from 'react';
import { QBox, QDataTable } from '@qualio/ui-components';
import { AppContext } from '../../contexts';

const ActionableEntitiesTable = () => {
  const { useActionableEntities } = useContext(AppContext);
  const { data: { items = [] } = {} } = useActionableEntities;
  return (
    <QBox marginTop={3}>
      <QDataTable columns={COLUMNS} data={items} hasPagination={false} />
    </QBox>
  );
};

const COLUMNS = [
  {
    Header: 'Type',
    accessor: 'actionable_entity_type',
  },
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Updated',
    accessor: 'updated_at',
  },
  {
    Header: 'Due',
    accessor: 'due_at',
  },
];

export default ActionableEntitiesTable;
