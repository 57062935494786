export enum EnvironmentEnum {
  PROD = 'production',
  STAGING = 'staging',
  LOCAL = 'local',
}

export type Environment = {
  name: string;
  apiUrl: string;
  ldClientSideId: string;
};
