import React, { useMemo } from 'react';
import { AppContext } from '../contexts';
import { useCurrentUser, useToastProvider, useAnalytics } from '@qualio/ui-components';
import { useQuery } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import qualioApi from '../api/qualioApi';

type AppProviderProps = {
  children: JSX.Element;
};

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const user = useCurrentUser();
  const toast = useToastProvider();
  const analytics = useAnalytics();
  const flags = useFlags();
  const { companyId, userId } = user;

  const useActionableDocumentCounts = useQuery(
    'actionableDocumentCounts',
    () => qualioApi.getActionableDocumentCounts(companyId, userId),
    {
      onError: () => {
        toast.showToast({
          title: 'Error',
          description: 'Something went wrong. We were unable to load the document counts.',
          status: 'error',
        });
      },
    },
  );

  const useActionableEntities = useQuery(
    'actionableEntities',
    () => qualioApi.getActionableEntities(companyId, userId),
    {
      onError: () => {
        toast.showToast({
          title: 'Error',
          description: 'Something went wrong. We were unable to load the dashboard documents.',
          status: 'error',
        });
      },
    },
  );

  const value = useMemo(
    () => ({
      user,
      toast,
      analytics,
      flags,
      useActionableDocumentCounts,
      useActionableEntities,
    }),
    [user, toast, analytics, flags, useActionableDocumentCounts, useActionableEntities],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
