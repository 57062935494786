import { CurrentUser } from '../types';

export const mockUser: CurrentUser = {
  companyId: 5,
  userId: 26,
  fullName: 'Daniel Tosh',
  companyName: 'Sport Nutrition',
  email: 'dtosh@example.com',
  permissions: {
    can_access_billing: true,
    can_activate_users: true,
    can_approve_doc: true,
    can_author_doc: true,
    can_change_doc_owner: true,
    can_deactivate_users: true,
    can_edit_products: true,
    can_edit_self_account: true,
    can_invite_users: true,
    can_make_doc_effective: true,
    can_manage_cc_templates: true,
    can_manage_company_settings: true,
    can_manage_company_users: true,
    can_manage_idp: true,
    can_manage_review_date: true,
    can_manage_tags: true,
    can_manage_templates: true,
    can_manage_trainees: true,
    can_manage_users: true,
    can_modify_doc_tags: true,
    can_retire_doc: true,
    can_review_doc: true,
    can_train_on_doc: true,
    can_unlock_users: true,
    can_view_all_docs: true,
    can_view_dashboard: true,
    can_view_library: true,
    can_view_products: true,
    can_view_report: true,
    can_view_training_company_dashboard: true,
    can_view_workspace: true,
    feature_enabled_product_development: true,
    feature_enabled_quality_events: true,
    can_view_migration: true,
    can_create_migration: true,
    can_prepare_migration: true,
    can_upload_migration_files: true,
    can_approve_migration: true,
  },
};
