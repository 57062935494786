import axios, { AxiosResponse } from 'axios';
import { environment } from '../environments/environment';

const client = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: environment.apiUrl,
  withCredentials: true,
});

const actionableDocumentsParams = {
  count: true,
  include_pending_approve: true,
  include_pending_make_effective: true,
  include_pending_periodic_review: true,
  include_pending_review: true,
  include_pending_training: true,
};

const actionableEntitiesParams = {
  limit: 10,
  offset: 0,
  order_by: '-updated_at',
};

const qualioApi = {
  getActionableDocumentCounts: (companyId: number, userId: number) => {
    return client
      .get(`/v2/${companyId}/users/${userId}/actionable_documents`, { params: actionableDocumentsParams })
      .then((res: AxiosResponse) => res.data);
  },
  getActionableEntities: (companyId: number, userId: number) => {
    return client
      .get(`/v2/${companyId}/users/${userId}/actionable_entities`, { params: actionableEntitiesParams })
      .then((res: AxiosResponse) => res.data);
  },
};

export default qualioApi;
