import React from 'react';
import ReactDOM from 'react-dom';
import { QualioQFrontEndProvider } from '@qualio/ui-components';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { QueryClient, QueryClientProvider } from 'react-query';
import { environment } from './environments/environment';
import { CurrentUser } from './types/currentUser';
import { mockAnalytics, mockUser } from './mocks';
import { AppProvider } from './providers';
import { EnvironmentEnum } from './types';
import ErrorBoundary from './views/ErrorBoundary';
import App from './App';

const isLocal = environment.name === EnvironmentEnum.LOCAL;

(window as any).renderInboxDashboard = async (
  containerId: string,
  user: CurrentUser,
  analytics: SegmentAnalytics.AnalyticsJS,
) => {
  const currentUser: CurrentUser = !user && isLocal ? mockUser : user;
  const currentAnalytics: SegmentAnalytics.AnalyticsJS =
    !analytics && isLocal ? (mockAnalytics as SegmentAnalytics.AnalyticsJS) : analytics;
  const LDProvider = await asyncWithLDProvider({
    clientSideID: environment.ldClientSideId,
    user: {
      key: `${user?.companyId}`,
    },
    options: {},
  });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  ReactDOM.render(
    <ErrorBoundary>
      <LDProvider>
        <QueryClientProvider client={queryClient}>
          <QualioQFrontEndProvider user={currentUser} analytics={currentAnalytics}>
            <AppProvider>
              <App />
            </AppProvider>
          </QualioQFrontEndProvider>
        </QueryClientProvider>
      </LDProvider>
    </ErrorBoundary>,
    document.getElementById(containerId),
  );
};

(window as any).unmountInboxDashboard = (containerId: string) => {
  try {
    if (document.getElementById(containerId) !== null) {
      ReactDOM.unmountComponentAtNode(document.getElementById(containerId)!);
    }
  } catch (e) {
    // even with the if statements its possible we have a race condition that is the if states the element exists BUT
    // then before we remove it the Application container has removed it
  }
};
