import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { QBox, QHeading, QButton, QInput, QStat, QMenuButton, QMenuItem, QMenuDivider } from '@qualio/ui-components';
import ActionableEntitiesTable from '../components/tables/ActionableEntitiesTable';
import { AppContext } from '../contexts';

const InboxDashboard: React.FC = () => {
  const { useActionableDocumentCounts } = useContext(AppContext);
  const navigate = useNavigate();
  const {
    data: { for_approver_group = 0, for_periodic_review = 0, for_review_group = 0, for_training_group = 0 } = {},
  } = useActionableDocumentCounts;

  const goToProfiles = () => navigate('/admin/profiles');

  return (
    <QBox display="flex" flexDirection="column" m={4}>
      <QHeading mb={4}>Dashboard</QHeading>
      <QBox display="flex" gap={2} mb={10} flexDirection={{ base: 'column', md: 'row' }}>
        <QBox display="flex" gap="2" justifyContent="space-between">
          <QButton width="50%" variant="outline">
            All actions
          </QButton>
          <QButton width="50%" variant="outline">
            Your actions
          </QButton>
        </QBox>
        <QBox marginLeft={{ base: 'none', md: 'auto' }}>
          <QInput width={{ base: '100%', md: '313px' }} iconLeftName="Search" placeholder="Search..." />
        </QBox>
        <QBox display="flex" gap={2} justifyContent="space-between">
          <QButton width={{ base: '50%', md: 'unset' }} variant="outline" rightIcon="Layers" onClick={goToProfiles}>
            View profiles
          </QButton>
          <QBox width={{ base: '50%', md: 'unset' }}>
            <QMenuButton buttonLabel="Create" iconName="PlusCircle" itemSize="md" variant="solid">
              <QMenuItem onClick={() => navigate('/workspace/documents/new')}>Document</QMenuItem>
              <QMenuItem onClick={() => navigate('/quality-events/events?open_modal=true')}>Event</QMenuItem>
              <QMenuDivider />
              <QMenuItem onClick={goToProfiles}>Profile</QMenuItem>
            </QMenuButton>
          </QBox>
        </QBox>
      </QBox>
      <QBox display="flex" gap={4} flexDirection={{ base: 'column', md: 'row' }}>
        <QStat data={for_periodic_review} iconName="RefreshCcw" label="Periodic review" variant="orange" />
        <QStat data={for_review_group} iconName="Activity" label="For review" variant="blue" />
        <QStat data={for_approver_group} iconName="CheckCircle" label="For approval" variant="pink" />
        <QStat data={for_training_group} iconName="UserCheck" label="For training" variant="purple" />
      </QBox>
      <ActionableEntitiesTable />
    </QBox>
  );
};

export default InboxDashboard;
