import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import InboxDashboard from './views/InboxDashboard';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Nest all other routes within this one to preserve the base path of /inbox-dashboard */}
        <Route path="/inbox-dashboard" element={<InboxDashboard />} />
      </Routes>
    </Router>
  );
};

export default App;
